<template>
    <div
        v-if="$route.name !== 'change-account' && !flagShowModalPin"
        class="navbar-container d-flex content align-items-center"
    >
        <!-- Left side Navbar (Logo) [shows in both] -->
        <div id="nav-left-section">
            <LogoNavBar
                class="logo-icon"
                :style="{
                    display: shouldHideLogo ? 'none !important' : 'block !important'
                }"
            />
        </div>

        <!-- Navbar content (Operator or PCP)-->
        <div
            id="nav-content-section"
            :class="{
                'ml-auto': $route.name.split('-')[0] === 'operator' && windowWidth > 480
            }"
        >
            <!-- Vertical Navbar toggle (mobile) -->
            <ul
                :class="[
                    'nav',
                    'navbar-nav',
                    'icon-menu',
                    'd-none',
                    $route.name.split('-')[0] !== 'operator' && 'pcp-mode'
                ]"
            >
                <li class="nav-item">
                    <b-link
                        class="nav-link"
                        @click="toggleVerticalMenuActive"
                    >
                        <IconMenu />
                    </b-link>
                </li>
            </ul>

            <!-- Navbar middle -->
            <div
                id="nav-content-section"
                class="bookmark-wrapper align-items-center flex-grow-1 d-flex horizontal-menu itens-menu"
            >
                <!-- Navbar icons -->
                <div
                    :class="[
                        'nav-content-icons',
                        $route.name.split('-')[0] === 'operator' ? 'operator' : ''
                    ]"
                >
                    <operator-mode class="d-block operator-mode" />
                    <OperatorNavigationMenu v-if="$route.name.split('-')[0] === 'operator'" />
                    <site-select
                        v-if="$route.name.split('-')[0] !== 'operator'"
                        class="d-block site"
                    />

                    <div
                        v-if="$route.name.split('-')[0] !== 'operator'"
                        class="line"
                    />
                </div>

                <!-- Menu & submenu (PCP only) -->
                <div
                    v-if="$route.name.split('-')[0] !== 'operator'"
                    id="nav-content-pcp"
                    class="header-navbar navbar-horizontal"
                >
                    <horizontal-nav-menu />
                </div>
            </div>
        </div>

        <!-- Right side Navbar (User information) -->
        <b-navbar-nav
            class="nav align-items-center"
            :class="{
                'ml-auto':
                    $route.name.split('-')[0] !== 'operator' ||
                    ($route.name.split('-')[0] === 'operator' && windowWidth <= 480)
            }"
            @mouseleave="toggleLanguageLeave"
        >
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
                :class="{ show: showMenu }"
            >
                <template #button-content>
                    <div class="d-flex user-nav">
                        <p class="user-name font-weight-bolder mb-0 add-ellipsis">
                            {{ userData.first_name }} {{ userData.last_name }}
                        </p>
                        <span class="user-status">{{ userData.job_role }}</span>
                    </div>

                    <b-avatar
                        v-if="userData.photo"
                        class="user-photo"
                        size="40"
                        variant="light-primary"
                        :src="userData.photo"
                    />

                    <b-avatar
                        v-else
                        variant="light-secondary"
                    >
                        <span
                            >{{ userData.first_name.substring(0, 1).toUpperCase()
                            }}{{ userData.last_name.substring(0, 1).toUpperCase() }}</span
                        >
                    </b-avatar>
                </template>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{ name: 'account-setting' }"
                >
                    <IconProfile />
                    <span>{{ $t("Profile") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    @click="redirectToChangeAccount"
                >
                    <IconReload />
                    <span>{{ $t("ChangeAccount") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{ name: 'support' }"
                >
                    <IconSupport />
                    <span>{{ $t("Support") }}</span>
                </b-dropdown-item>

                <a
                    class="navlink-lang w-100"
                    @mouseenter="toggleLanguageEnter"
                    @mouseleave="toggleExpandLanguage"
                >
                    <locale
                        type="userDropdown"
                        :class="{ show: expandLanguage }"
                    />
                </a>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    @click="logout"
                >
                    <IconLogout />
                    <span>{{ $t("Logout") }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>

    <div
        v-else
        class="d-flex align-items-center justify-content-center w-100 change-account-navbar"
        :style="`opacity: ${flagOverlayModalPin ? 0.7 : 1};`"
    >
        <MySFCIcon class="my-sfc-icon" />
    </div>
</template>

<script>
    import useJwt from "@/auth/jwt/useJwt";
    import { initialAbility } from "@/libs/acl/config";
    import useAppConfig from "@core/app-config/useAppConfig";
    import { computed } from "@vue/composition-api";

    import {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar
    } from "bootstrap-vue";
    import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
    import OperatorMode from "@core/layouts/components/app-navbar/components/OperatorMode.vue";
    import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
    import SiteSelect from "@core/layouts/components/app-navbar/components/SiteSelect.vue";
    import OperatorNavigationMenu from "@/@core/layouts/components/app-navbar/components/OperatorNavigationMenu/index.vue";
    import IconProfile from "@/assets/images/pages/profile-icon.svg";
    import IconReload from "@/assets/images/pages/reload-icon.svg";
    import IconSupport from "@/assets/images/pages/support-icon.svg";
    import IconLogout from "@/assets/images/pages/logout-icon.svg";
    import IconMenu from "@/assets/images/pages/menu-icon.svg";
    import MySFCIcon from "@/assets/images/icons/my-sfc-icon.svg";
    import router from "@/router";
    import { mapGetters } from "vuex";
    import HorizontalNavMenu from "../../@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue";
    import LogoNavBar from "../../assets/images/logo/logo-navbar-icon.svg";

    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar,
            // Navbar Components
            DarkToggler,
            OperatorMode,
            Locale,
            SiteSelect,
            LogoNavBar,
            HorizontalNavMenu,
            IconProfile,
            IconReload,
            IconSupport,
            IconLogout,
            IconMenu,
            MySFCIcon,
            OperatorNavigationMenu
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                userData: JSON.parse(localStorage.getItem("userData")),
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
                showMenu: false,
                expandLanguage: false,
                pcpAccess:
                    JSON.parse(localStorage.getItem("userData")).ability.find(
                        (element) => element.action === "manage"
                    ) ||
                    JSON.parse(localStorage.getItem("userData")).ability.find(
                        (element) => element.subject === "pcp"
                    ),
                contextoUsuario: {}
            };
        },
        computed: {
            ...mapGetters("app", ["flagShowModalPin", "flagOverlayModalPin"]),
            shouldHideLogo() {
                const isOperator = this.$route.name.split("-")[0] === "operator";
                return (
                    (this.windowWidth < 1252 && !isOperator) ||
                    (this.windowWidth <= 480 && isOperator)
                );
            }
        },
        mounted() {
            window.addEventListener("resize", this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            atualizaContextoUsuario(value) {
                this.contextoUsuario = value;
            },
            logout() {
                const lista = JSON.parse(localStorage.getItem("loggedAccountsInfo"));
                const { guid } = this.userData;
                const indexGuidAtual = lista.map((item) => item.guid).indexOf(guid);
                lista.splice(indexGuidAtual, 1);

                localStorage.setItem("loggedAccountsInfo", JSON.stringify(lista));

                useJwt
                    .logout(this.$router.currentRoute.params.prefix)
                    .then((response) => {
                        localStorage.clear();

                        // Reset ability
                        this.$ability.update(initialAbility);

                        // Delete cookie
                        const domain = window.location.href.includes("indsiders.com");
                        this.$cookies.remove("userInfo", null, domain ? "indsiders.com" : null);
                        this.$cookies.remove("company", null, domain ? "indsiders.com" : null);
                        // Redirect to login page
                        window.location.href = `${this.$myIndLink}login`;
                    })
                    .catch((error) => {});
            },
            toggleOperatorMode() {
                // this.isNavMenuHidden = !this.isNavMenuHidden
                return this.$route.name.split("-")[0] == "operator"
                    ? this.$router.push({ name: "home", params: { prefix: this.prefix } })
                    : this.$router.push({
                          name: "operator",
                          params: { prefix: this.prefix }
                      });
            },
            toggleLanguageEnter() {
                this.showMenu = true;
            },
            toggleLanguageLeave() {
                this.showMenu = false;
            },
            toggleExpandLanguage() {
                this.expandLanguage = false;
            },
            redirectToChangeAccount() {
                router.push({ name: "change-account" });
            },
            handleResize() {
                this.windowWidth = window.innerWidth;
            }
        },
        setup() {
            const { skin, isNavMenuHidden } = useAppConfig();

            const isDark = computed(() => skin.value === "dark");

            return { skin, isDark, isNavMenuHidden };
        }
    };
</script>
<style lang="scss">
    .add-ellipsis {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .my-sfc-icon {
        width: 85.77px;
        height: 30.4px;
    }
    .change-account-navbar {
        padding: 10px;
        height: 60px;
    }

    .navbar-brand-center:has(.change-account-navbar) {
        z-index: 999999999999 !important;
    }

    .navlink-lang {
        overflow: hidden;
        li {
            padding-left: 0;
            padding-right: 0;
            .test {
                padding: 0 1.28rem;
            }
            a {
                left: initial !important;
            }

            .dropdown-item {
                width: 13rem !important;
                padding-right: 0;
            }
        }
    }

    .nav-content-icons {
        display: flex;
        flex-direction: row;
    }

    .navbar {
        .navbar-container {
            padding: 9px 29px !important;
            .dropdown-user {
                .nav-link {
                    .user-name {
                        font-weight: 600 !important;
                        font-size: 14px;
                        line-height: 20px;
                        color: #4c4541;
                        max-width: 150px;
                    }
                    .user-status {
                        font-weight: 400 !important;
                        font-size: 12px;
                        line-height: 16px;
                        color: #998f8a;
                    }
                    .user-photo {
                        width: 38px !important;
                        height: 38px !important;
                    }
                }
                .dropdown-menu {
                    .nav-locale {
                        flex-direction: column;
                        &.en {
                            .nav-link {
                                left: -28px !important;
                            }
                        }
                        .nav-link {
                            left: -14px;
                            svg {
                                right: -25px;
                                top: 1px !important;
                            }
                        }
                        .dropdown-menu {
                            width: 166px;
                            padding-bottom: 0;
                            background: transparent;
                            top: 5px;
                            left: 0 !important;
                            animation: none !important;
                            .item-locale {
                                a {
                                    margin-bottom: 0;
                                    background: #fff !important;
                                }
                                &:hover {
                                    a {
                                        background: #ffede2 !important;
                                    }
                                }
                            }
                        }
                        &.show {
                            padding-bottom: 0;
                            background: #ffdbc4 !important;
                        }
                    }
                    .dropdown-item {
                        span {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #998f8a;
                            margin-left: 9px;
                        }
                        svg {
                            path {
                                fill: #998f8a;
                            }
                        }
                        &:hover {
                            background-color: #ffede2;
                            span {
                                color: #974900;
                            }
                            svg {
                                path {
                                    fill: #974900 !important;
                                }
                            }
                            a {
                                span {
                                    color: #974900 !important;
                                }
                            }
                        }
                        .nav-link {
                            padding: 0;
                        }
                    }
                }
            }
            .bookmark-wrapper {
                .operator-mode {
                    margin-left: 15px;
                    border-radius: 5px;
                    .nav-link {
                        padding: 9px 12px;
                        color: #998f8a !important;
                    }
                    &:hover {
                        background-color: #ffede2;
                        .nav-link {
                            svg {
                                path {
                                    fill: #974900;
                                }
                            }
                        }
                    }
                }
                .site {
                    padding: 9px 12px;
                    border-radius: 5px;
                    .nav-link {
                        padding: 0;
                        color: #998f8a !important;
                    }
                    &:hover {
                        background-color: #ffede2;
                        .nav-link {
                            .icon-arrow-down {
                                path {
                                    fill: #974900;
                                }
                            }
                            .icon-database {
                                path {
                                    stroke: #974900;
                                }
                            }
                        }
                    }
                }
                .line {
                    width: 2px;
                    height: 30px;
                    background: #ece0db;
                    margin: 0 26px;
                }
                .header-navbar.navbar-horizontal {
                    .navbar-container {
                        padding: 0 !important;
                        .nav-item {
                            padding: 0 !important;
                            &:hover {
                                .nav-link {
                                    background: #ffede2 !important;
                                }
                                span {
                                    color: #974900 !important;
                                }
                                svg {
                                    path {
                                        fill: #974900 !important;
                                    }
                                }
                            }
                            &.active {
                                .nav-link {
                                    background: #974900 !important;
                                    box-shadow: none !important;
                                }
                                span {
                                    color: #ffff !important;
                                }
                                svg {
                                    path {
                                        fill: #ffff !important;
                                    }
                                }
                                .dropdown-menu {
                                    .dropdown-submenu {
                                        &.sidebar-group-active {
                                            .dropdown-toggle {
                                                background-color: #ffdbc4 !important;
                                                span {
                                                    color: #974900 !important;
                                                }
                                                svg {
                                                    path {
                                                        fill: #974900 !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dropdown-item.router-link-active {
                                        background-color: #ffdbc4 !important;
                                        span {
                                            color: #974900 !important;
                                        }
                                        svg {
                                            path {
                                                fill: #974900 !important;
                                            }
                                        }
                                    }
                                }
                            }
                            &:focus {
                                .nav-link {
                                    background: #ffdbc4 !important;
                                }
                                span {
                                    color: #974900 !important;
                                }
                                svg {
                                    path {
                                        fill: #974900 !important;
                                    }
                                }
                            }
                            .nav-link {
                                display: flex;
                                align-items: center !important;
                                svg {
                                    path {
                                        fill: #998f8a;
                                    }
                                }
                                span {
                                    color: #998f8a;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                            .dropdown-menu {
                                margin: 0 !important;
                                padding: 0;
                                box-shadow: none !important;
                                background-color: transparent !important;
                                ul {
                                    padding: 8px 0 !important;
                                    box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
                                    margin-top: 4px;
                                    background-color: #ffff !important;
                                    border-radius: 6px;
                                    .dropdown-item {
                                        span {
                                            color: #998f8a !important;
                                        }
                                        svg {
                                            path {
                                                fill: #998f8a !important;
                                            }
                                        }
                                        &:hover {
                                            background-color: #ffede2 !important;
                                            .ooe-icon {
                                                path {
                                                    stroke: #974900;
                                                }
                                            }
                                            span {
                                                color: #974900 !important;
                                            }
                                            svg {
                                                path {
                                                    fill: #974900 !important;
                                                }
                                            }
                                        }
                                        &:focus {
                                            background-color: #ffdbc4 !important;
                                            span {
                                                color: #974900 !important;
                                            }
                                            svg {
                                                path {
                                                    fill: #974900 !important;
                                                }
                                            }
                                        }
                                    }
                                    .dropdown-menu {
                                        box-shadow: 0 5px 25px rgb(34 41 47 / 10%) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1252px) {
        .horizontal-menu.itens-menu {
            display: none !important;
        }
        .horizontal-menu.itens-menu:has(.operator) {
            display: flex !important;
        }
        .icon-menu.pcp-mode {
            display: block !important;
        }
    }

    @media (max-width: 768px) {
        .operator-mode.tablet,
        .site.tablet {
            display: none !important;
        }
    }

    @media (max-width: 480px) {
        .icon-menu {
            display: block !important;
            .nav-link {
                padding-left: 0px !important;
            }
        }
        .operator-mode.tablet,
        .site.tablet {
            display: none !important;
        }
        .dropdown-user .dropdown-menu {
            margin-top: 0 !important;
        }
        .horizontal-menu.itens-menu:has(.operator) {
            display: none !important;
        }
        .navbar {
            .navbar-container {
                padding: 11px 16px !important;
            }
        }
    }
</style>
